#sync-alert {
  opacity: 0;
  transition: opacity 0.5s ease-out !important;
  z-index: 2;
}

.show {
  opacity: 1 !important;
  transition: opacity 0.5s ease-out !important;
  z-index: 2;
}

.rotating {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
