@import 'styles/variables/colors.module';

.root {
  border-bottom: solid 0.1px $grey-7;
  padding-top: 20px;
  padding-bottom: 20px;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}
