@import '~styles/variables/colors.module.scss';
@import 'components/common/typography/styles.module.scss';

.root {
  &:not(:global .ant-form-item-has-error) {
    margin-bottom: 0;
  }

  & :global(.ant-form-item-explain-error) {
    position: absolute;
    top: 100%;
    color: $red-2;
  }

  & :global(.ant-form-item-label) {
    padding: 0 0 5px;

    & > label {
      display: flex;
      color: $grey-11;
      font-size: 14px;
      line-height: 1.71;
    }
  }
}
