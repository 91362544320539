@import '~styles/variables/colors.module.scss';

.root {
  &:global(.ant-upload-drag) {
    text-align: initial;
    border-color: $grey-10;
    background: $purple-transparent !important;
    border-radius: 20px;

    &:not(.ant-upload-disabled):hover {
      border-color: $primary-color;
    }
  }

  &:global(.ant-upload.ant-upload-drag) :global(.ant-upload) {
    padding: 0;
  }
}
