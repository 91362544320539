@import '~styles/variables/colors.module.scss';

@mixin message($border-color) {
  & :global(.ant-message-notice-content) {
    vertical-align: middle;
    box-shadow: none;
    border: 1px solid $border-color;
    padding: 16px 13px 16px 15px;
    border-radius: 5px;
  }
}
.success {
  @include message($green-4);
}

.error {
  @include message($red-2);
}

.warning {
  @include message($yellow-6);
}
