@import '~styles/variables/colors.module.scss';

.root {
  &:not([disabled]):hover {
    color: $primary-color;
  }
}

.defaultUnderlineVariant {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.alwaysUnderlineVariant {
  text-decoration: underline;
}

.noneUnderlineVariant {
  text-decoration: none;
}
