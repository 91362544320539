@import 'styles/variables/colors.module';
@import 'components/common/typography/styles.module.scss';
@import 'components/common/form/text-field/styles.module.scss';

.root {
  width: 100%;

  &:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    @extend .input-regular;

    height: auto;

    &:after,
    :global(.ant-select-selection-item),
    :global(.ant-select-selection-placeholder) {
      line-height: inherit;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
    }
  }

  &:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector) {
    @extend .input-focus;
  }

  &:global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    @extend .input-disabled;
  }

  &:global(.ant-select-selection-placeholder) {
    @extend .input-placeholder;
  }

  &:global(.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input) {
    height: 100%;
  }

  &:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search) {
    left: $padding-x;
  }

  &:global(.ant-select .ant-select-arrow) {
    transform: translateY(-50%);
    padding-right: 25px;
    margin-top: 0;
  }

  &:global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .icon-arrow_down) {
    color: $grey-3;
  }

  &.touched {
    :global(.ant-select-selector) {
      border-color: $grey-transparent-2;
    }
  }

  &.error:global(:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector) {
    @extend .input-error;
  }

  & :global(.ant-select-item .ant-select-item-group) {
    @extend .body-7;
  }

  &:global(.ant-select-multiple) {
    & :global(.ant-select-selection-item) {
      border: 0;
      padding: 0 15px;
      border-radius: 5px;
      margin: 0;
      background-color: $grey-12;
      height: auto;
    }

    & :global(.ant-select-selection-overflow) {
      line-height: inherit;
      grid-gap: 5px;
    }

    & :global(.ant-select-selection-search) {
      margin-top: 0;
      margin-bottom: 0;
      margin-inline-start: 0;
    }

    & :global(.ant-select-selection-placeholder) {
      left: $padding-x;
    }
  }

  & :global(.ant-select-selection-item-remove) {
    font-size: inherit;
  }
}

.dropdown {
  &:global(.ant-select-dropdown) {
    border-radius: 6px;
    border: solid 1px $grey-transparent-2;
    box-shadow: none;
    top: 141px;
    padding: 0;
  }

  :global(.ant-select-item) {
    padding: 10px $padding-x;
  }

  :global(.ant-select-item.ant-select-item-group) {
    @extend .body-7;
  }

  :global(.ant-select-item-option .ant-select-item-option-state) {
    align-self: center;
  }

  :global(.ant-select-item-option-content) {
    @extend .body-2;
    color: $black-3;
  }

  :global(.ant-select-item-option-active:not(.ant-select-item-option-disabled)) {
    background-color: $grey-1;
  }

  :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
    background-color: $purple-6;
  }

  :global(.ant-select-item-option-disabled) {
    opacity: 0.5;
  }

  :global(.rc-virtual-list-scrollbar-show) {
    width: 12px !important;
    background: $white-1;
    display: block !important;

    & :global(.rc-virtual-list-scrollbar-thumb) {
      background: $grey-transparent-6 !important;
      display: block !important;
      width: auto !important;
      left: 3px !important;
      right: 3px !important;
    }
  }

  :global(.rc-virtual-list-scrollbar-thumb) {
    display: none;
  }
}
