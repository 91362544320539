#error-alert-class {
  opacity: 0;
  transition: opacity 0.5s ease-out !important;
  z-index: 2;
}

.showAlert {
  opacity: 1 !important;
  transition: opacity 0.5s ease-out !important;
  z-index: 2;
}
