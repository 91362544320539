@import 'styles/variables/colors.module';

.root {
  position: relative;

  &.has-circle {
    border-radius: 50%;
    background: $white-1;

    &:before {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.has-circle {
}

.circle {
  display: inline-flex;
  z-index: 0;
  border-radius: 50%;
}
