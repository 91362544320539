.root {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > *:not(.overlay) {
    opacity: 1;
  }

  &.pending {
    & > *:not(.overlay) {
      opacity: 0;
    }

    .overlay {
      display: flex;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.preloader {
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
}
