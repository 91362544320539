@import 'styles/variables/colors.module';
@import 'components/common/typography/styles.module.scss';

.root {
  @extend .body-2;

  display: flex;
  align-items: center;
  border: 0;
  padding: 0 15px;
  border-radius: 5px;
  margin: 0;
  height: auto;
  line-height: inherit;
  max-width: 100%;

  & .content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  &.old {
    background: $yellow-1;
  }

  &.invalid {
    background: $red-1;
  }
}
