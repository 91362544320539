@import 'styles/variables/colors.module';
@import 'components/common/form/text-field/styles.module.scss';

.root {
  width: 100%;

  &:global(.ant-input-textarea .ant-input) {
    @extend .input-regular;

    &::placeholder {
      @extend .input-placeholder;
    }

    &:focus {
      @extend .input-focus;
    }

    &:hover {
      @extend .input-hover;
    }
  }

  &.error :global(.ant-input) {
    @extend .input-error;
  }
}
