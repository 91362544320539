@import 'styles/variables/colors.module';

$width: 2px;

.root {
  overflow: auto;

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    background: $transparent;
  }
}

.lightVariant {
  &::-webkit-scrollbar-thumb {
    background: var(--color, $grey-1);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: var(--hover-color, $black-1);
    }
  }
}

.darkVariant {
  &::-webkit-scrollbar-track {
    background: $grey-2;
    visibility: hidden;
    border: #{$width}px solid $transparent;
    background-clip: padding-box;
  }

  &:hover::-webkit-scrollbar-track {
    visibility: visible;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color, $black-1);
    visibility: hidden;
    border: #{$width}px solid $transparent;
    background-clip: padding-box;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
