@import '~styles/variables/colors.module.scss';
@import 'components/common/typography/styles.module.scss';

.root {
  @extend .body-5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 11px 25px;
  height: 50px;
  min-width: 185px;

  & span:first-letter {
    text-transform: uppercase;
  }

  &:global(.ant-btn-primary:not([disabled])) {
    background-color: $primary-color;
    border-color: $primary-color;
    &:hover {
      background-color: $blue-2;
      border-color: $blue-2;
    }
    &:active {
      background-color: $blue-1;
      border-color: $blue-1;
    }
  }

  &:global(.ant-btn-default:not([disabled])) {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $primary-color;
    &:hover {
      background-color: $purple-2;
      border-color: $purple-2;
    }
    &:active {
      background-color: $purple-4;
      border-color: $purple-4;
    }
  }

  &:global(.ant-btn-link:not([disabled])) {
    @extend.body-4;
    display: inline-block;
    padding: 0;
    line-height: 1.5;
    height: auto;
    color: $black-3;
    &:active {
      color: $primary-color;
      span:last-of-type {
        text-decoration: underline;
      }
    }
    &:hover {
      color: $primary-color;
    }
  }

  &:global(.ant-btn-link[disabled]) {
    color: $grey-3;
  }

  &:global(.ant-btn-lg) {
    height: 54px;
    min-width: auto;
  }

  &:global(.ant-btn-sm) {
    height: 44px;
    min-width: 139px;
    padding: 11px 13px;
  }

  &.tiny {
    font-size: 14px;
    padding: 10px 25px;
    height: 40px;
    min-width: 130px;
  }

  &.delete {
    color: $red-2;
    background-color: $red-4;
    border: $red-4;

    &:hover {
      color: $white-1;
      background-color: $red-2;
      border-color: $red-2;
    }

    &:active {
      background-color: $red-3;
      border-color: $red-3;
      color: $white-1;
    }
  }

  &:global(.ant-btn-text:not([disabled])) {
    padding: 10px 25px;
    height: auto;
    @extend .body-2;
    line-height: 1.5;
  }

  &:global(.ant-btn-text > .icon-plus + span) {
    margin-left: 10px;
  }

  @mixin circle-button($size) {
    padding: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    min-width: auto;
  }

  &.circle-large {
    @include circle-button(54px);
  }

  &.circle-small {
    @include circle-button(38px);
  }

  @mixin open-button($width, $radius) {
    font-size: 10px;
    padding: 0;
    height: 29px;
    width: $width;
    border-radius: $radius;
    min-width: auto;
  }

  &.open-button-large {
    @include open-button(149px, 15px);
  }

  &.open-button-small {
    @include open-button(60px, 7px);
  }

  &.button-platform {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 129px;
    min-width: 134px;
    width: 134px;
    gap: 16px;
    padding: 0;
    border-radius: 8px;
    border: 1px solid $grey-transparent-2;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    cursor: pointer;

    &:hover {
      border-color: $grey-5;
    }

    &:focus {
      border-color: $blue-3;
    }

    &:active {
      border-color: $grey-transparent-2;
    }
  }

  &.google-platform {
    width: 182px;
    min-width: 182px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 $grey-transparent-7;
    border-color: transparent;
    font-size: 14px;
    line-height: 1.29;
    color: $grey-16;

    &[ant-click-animating-without-extra-node='true']::after {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: 0 1px 2px 0 $grey-transparent-7;
    }
  }

  &.facebook-platform {
    height: 40px;
    min-width: 235px;
    width: 235px;
    background-color: $blue-5;
    border-radius: 4px;
    border-color: transparent;
    box-shadow: none;
    color: $secondary-color;
    font-size: 16px;

    &[ant-click-animating-without-extra-node='true']::after {
      box-shadow: none !important;
    }
  }
}
