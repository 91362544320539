$primary-color: #0f0ff4;
$secondary-color: #f4f4ff;
$spinner-1: rgba($primary-color, 0.25);

$white-1: #fff;

$black-1: #000;
$black-2: #050404;
$black-3: #111315;
$black-4: rgba(17, 19, 21, 0.7);

$light-grey: #f7f8f9;
$grey-1: #f9f9fa;
$grey-2: #acb4bd;
$grey-3: #adb6bd;
$grey-4: #d2d7db;
$grey-5: #637381;
$grey-6: #d8dcdf;
$grey-7: rgba(99, 115, 129, 0.21);
$grey-8: rgba(0, 0, 0, 0.05);
$grey-9: rgba(0, 0, 0, 0.08);
$grey-10: rgba(99, 115, 129, 0.49);
$grey-11: #626c75;
$grey-12: rgba(15, 15, 244, 0.04);
$grey-13: rgba(99, 115, 129, 0.25);
$grey-14: rgba(99, 115, 129, 0.06);
$grey-15: rgba(99, 115, 129, 0.15);
$grey-16: #757575;
$dark-grey: #19222c;

$blue-1: #98d0ff;
$blue-2: #39a3ff;
$blue-3: #0f0ff4;
$blue-4: #3b5999;
$blue-5: #1877f2;

$purple-1: #f2f2ff;
$purple-2: #dfdffe;
$purple-3: #d3d3fa;
$purple-4: #b6b6fc;
$purple-5: #c1c1fc;
$purple-6: #f5f5ff;
$purple-7: #e6e6fe;
$purple-8: #c529f8;
$purple-9: #aeaefc;

$red-1: #fee;
$red-2: #d92727;
$red-3: #ff8181;
$red-4: #ffeeee;
$red-5: #f44336;

$yellow-1: #fff9df;
$yellow-2: #d6ae03;
$yellow-3: #ffef72;
$yellow-4: #f0c300;
$yellow-5: #f57120;
$yellow-6: #f0c32c;

$green-1: #e9fde1;
$green-2: #54aa34;
$green-3: #a6eb8c;
$green-4: #52c427;
$green-5: #10e7be;

$transparent: transparent;

$black-transparent: rgba(0, 0, 0, 0.06);
$grey-transparent-1: rgba 255 255 255, 0.1;
$grey-transparent-2: rgba(99, 115, 129, 0.5);
$grey-transparent-3: rgba (99 115 129, 0.05);
$grey-transparent-4: rgba(17, 19, 21, 0.06);
$grey-transparent-5: rgba(99, 115, 129, 0.2);
$grey-transparent-6: rgba(99, 115, 129, 0.12);
$grey-transparent-7: rgba(0, 0, 0, 0.16);
$grey-transparent-8: rgba(250, 250, 250, 0.5);
$purple-transparent: rgba(15, 15, 244, 0.02);
$purple-transparent-1: rgba(15, 15, 244, 0.16);

$red-transparent: rgba(255, 43, 43, 0.08);
$yellow-transparent: rgba(255, 215, 43, 0.15);
$green-transparent: rgba(91, 237, 36, 0.13);

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  black: $black-1;

  mainFontColor: $dark-grey;
  secondaryFontColor: $grey-5;
  darkTextColor: $grey-5;

  disabledTextColor: $grey-3;
  disabledBackgroundColor: $light-grey;
  disabledBorderColor: $grey-4;
  inputTextColor: $grey-5;
  inputBorderColor: $grey-transparent-2;
  focusColor: $primary-color;

  hoverBackgroundColor: $light-grey;
  selectedBackgroundColor: $purple-1;

  primaryButtonColor: $primary-color;
  primaryFocusButton: $blue-1;
  primaryHoverButton: $blue-2;

  secondaryButtonColor: $purple-1;
  secondaryFocusButton: $purple-4;
  secondaryHoverButton: $purple-2;

  errorColor: $red-2;
  errorBackground: $red-transparent;

  warningCollor: $yellow-2;
  warningBackground: $yellow-transparent;

  successColor: $green-2;
  successBackground: $green-transparent;

  transparent: $transparent;

  yellow1: $yellow-1;
  yellow3: $yellow-3;
  yellow4: $yellow-4;
  yellow5: $yellow-5;
  yellow6: $yellow-6;
  blue3: $blue-3;
  lightGrey: $light-grey;
  green3: $green-3;
  grey2: $grey-2;
  grey5: $grey-5;
  grey7: $grey-7;
  grey1: $grey-1;
  grey11: $grey-11;
  grey12: $grey-12;
  grey13: $grey-13;
  grey14: $grey-14;
  white1: $white-1;
  black3: $black-3;
  black4: $black-4;
  blackTransparent: $black-transparent;
  purple5: $purple-5;
  purple6: $purple-6;
  purple7: $purple-7;
  purple8: $purple-8;
  green3: $green-3;
  green4: $green-4;
  greyTransparent2: $grey-transparent-2;
  greyTransparent5: $grey-transparent-5;
  green5: $green-5;
  red3: $red-3;
  greyTransparent4: $grey-transparent-4;
  blue4: $blue-4;
  red5: $red-5;
}
