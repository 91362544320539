.arrow-btn {
  /* width: 40px; */
  height: 40px;
  border-radius: 5px;
  background: #f4f4ff;
  padding: 0px 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
