@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');
@import-normalize;
@import './styles/variables/typography.module.scss';

@font-face {
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  src: local('icons'), url('./assets/fonts/icons/icons.woff') format('woff'),
    url('./assets/fonts/icons/icons.ttf') format('truetype'),
    url('./assets/fonts/icons/icons.svg#OpenSans') format('svg');
}

html {
  min-width: 1366px;
}

body {
  margin: 0;
  font-family: $font-family;
  line-height: $line-height;
  -webkit-font-smoothing: antialiased;
}
