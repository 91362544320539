@import 'styles/variables/colors.module';

.root {
  padding-bottom: 0;

  & :global(.ant-modal) {
    padding-bottom: 0;
  }

  & :global(.ant-modal-content) {
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: 10px 10px 47px 0 $grey-9;
    background: $white-1;
    padding: 30px 30px;

    & :global(.ant-modal-body) {
      flex: 1;
      display: flex;
      min-height: 0;
      padding: 0;
    }
  }

  & :global(.ant-modal-header) {
    background: $transparent;
    border: 0;
    padding: 0;
  }

  & :global(.ant-modal-close-x) {
    width: auto;
    height: auto;
    font-size: unset;
    line-height: unset;
    margin: 60px 50px;
  }
}

:global(.ant-modal-mask) {
  opacity: 0.58;
  backdrop-filter: blur(18.4px);
  background: $white-1;
}

.preloader-height-auto {
  height: auto;
}
