@import '~styles/variables/colors.module';
@import '~components/common/typography/styles.module.scss';

.root {
  overflow: visible;

  &:global(.ant-tabs-top) {
    :global(.ant-tabs-nav) {
      & :global(.ant-tabs-ink-bar) {
        background-color: $blue-3;
      }
    }

    &:global(.has-error) {
      :global(.ant-tabs-ink-bar) {
        background-color: $red-2;
      }
    }

    :global(.ant-tabs-tab) {
      margin: 0;
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      margin-right: 10px;

      @media (min-width: 1920px) {
        max-width: 100%;
      }

      &:hover {
        color: $black-3;
      }

      &:global(.ant-tabs-tab-active) {
        & :global(.ant-tabs-tab-btn) {
          color: $black-3;
        }
      }
    }

    :global(.ant-tabs-nav-list) {
      width: 100%;
    }

    :global(.ant-tabs-nav) {
      margin-bottom: 0;
    }
  }

  &:global(.ant-tabs-bottom) {
    :global(.ant-tabs-nav) {
      width: 100%;
      margin: 0 auto;

      &:global(.ant-tabs-ink-bar) {
        background-color: $blue-3;
      }
    }

    :global(.ant-tabs-tab) {
      margin: 0;
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      margin-right: 10px;

      @media (min-width: 1920px) {
        max-width: 100%;
      }

      &:hover {
        color: $black-3;
      }

      &:global(.ant-tabs-tab-active) {
        background-color: $purple-1;

        & :global(.ant-tabs-tab-btn) {
          color: $blue-3;
        }
      }
    }

    :global(.ant-tabs-nav-list) {
      width: 100%;
    }

    :global(.ant-tabs-nav) {
      margin-bottom: 0;
    }
  }
}
