@import '~styles/variables/colors.module.scss';

.root {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > *:not(.overlay) {
    opacity: 1;
  }

  &.pending {
    .overlay {
      display: flex;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: $grey-transparent-8;
}

.preloader {
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
}
