@import 'styles/variables/colors.module';
@import 'components/common/typography/styles.module.scss';

$height: 46px;
$padding-x: 15px;
$padding-y: 6px;

.input-placeholder {
  color: $grey-11;
}

.input-focus {
  border-color: $blue-3;
  box-shadow: none;
  outline: none;
}

.input-hover {
  border: solid 1px $grey-11;
}

.input-disabled {
  border: solid 1px $grey-4;
  background-color: $light-grey;
  color: $grey-3;
}

.input-error {
  box-shadow: none !important;
  border-color: $red-2 !important;

  &:focus {
    box-shadow: none;
    border-color: $red-2 !important;
  }
}

.input-regular {
  @extend .body-2;

  min-height: 54px;
  line-height: $height - $padding-y;
  color: $black-3;
  padding: $padding-y $padding-x;
  border-radius: 5px;
  border: solid 1px $grey-transparent-2;
}

.input {
  @extend .input-regular;

  &::placeholder {
    @extend .input-placeholder;
  }

  &:focus {
    @extend .input-focus;
  }

  &:hover {
    @extend .input-hover;
  }

  &[disabled] {
    @extend .input-disabled;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    background-color: $white-1 !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

.root {
  width: 100%;

  &:global(.ant-input) {
    @extend .input;
  }

  &.touched {
    border-color: $grey-transparent-2;
  }

  &.error input {
    @extend .input-error;
  }

  &:global(.ant-input-affix-wrapper.ant-input-password) {
    $padding-right: $padding-x * 3;

    padding: 0;
    border-radius: 0;
    border: 0;

    & :global(.ant-input) {
      @extend .input;

      padding-right: $padding-x * 3;
    }

    & :global(.ant-input-suffix) {
      position: absolute;
      top: 50%;
      right: $padding-right * 0.5;
      transform: translate(50%, -50%);
      margin: 0;
    }
  }
}
