.inherit {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

%headline {
  font-size: 16px;
  line-height: 1.38;
}

.headline-1 {
  font-size: 24px;
  line-height: 1.33;
  font-weight: 700;
}

.headline-2 {
  @extend %headline;
  font-weight: 700;
}

.headline-3 {
  @extend %headline;
  font-weight: 600;
}

.headline-4 {
  @extend %headline;
  font-weight: 400;
}

.headline-5 {
  font-size: 24px;
  line-height: 1.67;
  font-weight: 800;
}

.headline-6 {
  font-size: 24px;
  line-height: 1.67;
  font-weight: 700;
}

.headline-7 {
  font-size: 21px;
  line-height: 1.15;
  font-weight: 600;
}

.headline-8 {
  font-size: 29px;
  line-height: 2.24;
  font-weight: 700;
}

.body-1 {
  @extend %headline;
  font-weight: 400;
}

.body-2 {
  @extend %headline;
  font-weight: 600;
}

.body-3 {
  font-size: 14px;
  font-weight: 600;
}

.body-4 {
  font-size: 14px;
  font-weight: 400;
}

.body-5 {
  @extend %headline;
  font-weight: 800;
}

.body-6 {
  font-size: 13px;
  line-height: 1.38;
  font-weight: 600;
}

.body-7 {
  font-size: 14px;
  line-height: 1.71;
}

.body-8 {
  font-size: 26px;
  line-height: 1.56;
  font-weight: 700;
}

.body-9 {
  font-size: 12px;
  line-height: 2.17;
  font-weight: 600;
}

.body-10 {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 400;
}

.body-11 {
  font-size: 12px;
  line-height: 2;
  font-weight: 700;
}

.body-12 {
  font-size: 18px;
  line-height: 1.44;
}

.body-13 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.body-14 {
  font-size: 13px;
  font-weight: 800;
}

.body-15 {
  font-size: 16px;
  font-weight: 700;
}

.body-16 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

.body-17 {
  font-size: 10px;
  font-weight: bold;
  line-height: 2.4;
}

.body-18 {
  font-size: 12px;
  line-height: 1.83;
}

.body-19 {
  font-size: 350px;
  font-weight: 900;
  line-height: 478px;
}

.body-20 {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
}

.body-21 {
  font-size: 28px;
  font-weight: 900;
  line-height: 38px;
}

.body-22 {
  font-size: 16px;
  line-height: 1.81;
}

.body-23 {
  font-size: 18px;
  line-height: 1.44;
  font-weight: bold;
}

.body-24 {
  font-size: 18px;
  line-height: 2.22;
  font-weight: normal;
}

.body-25 {
  font-size: 16px;
  line-height: 2.5;
  font-weight: bold;
}

.body-26 {
  @extend %headline;
  line-height: 1.81;
  font-weight: bold;
}

.body-27 {
  font-size: 16px;
  font-weight: bold;
  line-height: 2.5;
}

.body-28 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.81;
}

.body-29 {
  font-size: 12px;
  font-weight: bold;
}

.body-30 {
  font-size: 14px;
  font-weight: bold;
}
