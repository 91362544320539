@import 'styles/variables';

.root {
  border-radius: 50%;
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;

  @-webkit-keyframes spinnerA {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
