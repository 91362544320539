@import '~styles/variables/colors.module';
@import '~components/common/typography/styles.module.scss';
@import '~components/common/form/text-field/styles.module.scss';

.root {
  &.error {
    border-color: $red-1;
  }

  &:global(.ant-picker) {
    @extend .input-regular;

    width: 100%;

    &:global(.ant-picker-focused) {
      @extend .input-focus;
    }

    & :global(.ant-picker-input) > input {
      @extend .body-2;

      line-height: inherit;

      &::placeholder {
        @extend .input-placeholder;
      }

      &[value] {
        color: $grey-11;
      }
    }

    & :global(.ant-picker-range-separator) {
      padding: 0 15px;
    }

    & :global(.anticon) {
      [data-icon='calendar'] {
        width: 20px;
        height: 20px;
        fill: $blue-3;
      }
    }

    & :global(.ant-picker-active-bar) {
      background: $blue-3;
    }

    & :global(.ant-picker-clear) {
      background: $transparent;
      position: relative;
      right: 0;
      margin-left: $padding-x;
      transform: none;
      opacity: 1;
    }

    &:global(.ant-picker-disabled) {
      background-color: $light-grey;
      border-color: $grey-4;

      & :global(.ant-picker-input) > input {
        &[value] {
          background-color: $light-grey;
          color: $grey-3;
        }
      }
    }

    &.containedVariant {
      background: $blue-3;
      border: solid 1px $blue-3;

      & :global(.ant-picker-input) > input {
        &::placeholder {
          color: $white-1;
        }

        &[value] {
          color: $white-1;
        }
      }

      & :global(.ant-picker-separator) {
        color: $white-1;
      }

      & :global(.icon-calendar) {
        color: $white-1;
      }

      & :global(.ant-picker-clear) {
        [data-icon='close-circle'] {
          fill: $white-1;
        }
      }
    }
  }
}

.dropdown,
.dropdown.containedDropdownVariant {
  & :global(.ant-picker-panels) {
    @extend .body-3;
    color: $black-3;
  }

  & :global(.ant-picker-cell) {
    &:hover {
      & :global(.ant-picker-cell-inner) {
        background: $purple-1 !important;
      }
    }
  }

  & :global(.ant-picker-cell-inner) {
    border-radius: 5px !important;
  }

  & :global(.ant-picker-cell-today) {
    &:global(.ant-picker-cell-selected) {
      & :global(.ant-picker-cell-inner) {
        color: $white-1;
      }
    }

    & :global(.ant-picker-cell-inner) {
      background: transparent;
      color: $black-3;
      &::before {
        border-radius: 5px;
        border-color: $blue-3;
      }
    }
  }

  & :global(.ant-picker-cell-disabled) {
    &:global(.ant-picker-cell-today) {
      & :global(.ant-picker-cell-inner) {
        opacity: 0.5;
      }
    }

    &::before {
      background: $transparent;
    }
  }

  & :global(.ant-picker-cell-in-range) {
    &::before {
      background: $purple-1;
    }
  }

  & :global(.ant-picker-cell-in-view) {
    &:global(.ant-picker-cell-range-start),
    &:global(.ant-picker-cell-range-end) {
      & :global(.ant-picker-cell-inner) {
        background: $blue-3;
      }
    }
  }

  // TimePanel
  & :global(.ant-picker-time-panel) {
    @extend .body-3;
    color: $black-3;
  }

  & :global(.ant-picker-time-panel-cell) {
    &:hover {
      & :global(.ant-picker-time-panel-cell-inner) {
        background: $purple-1 !important;
      }
    }

    &:global(.ant-picker-time-panel-cell-selected) {
      & :global(.ant-picker-time-panel-cell-inner) {
        background: $purple-1 !important;
      }
    }
  }

  // Footer button
  & :global(.ant-picker-footer) {
    & :global(.ant-btn-sm) {
      @extend .body-3;
      height: auto;
      padding: 5px 20px;
      border-radius: 6px;

      &:not([disabled]) {
        color: $blue-3;
        background-color: $secondary-color;
        border-color: $secondary-color;

        &:hover {
          background-color: $purple-2;
          border-color: $purple-2;
        }

        &:focus {
          background-color: $purple-4;
          border-color: $purple-4;
        }
      }
    }
  }
}
