@import 'styles/variables/colors.module';
@import 'components/common/typography/styles.module.scss';

.root {
  &:global(.ant-radio-wrapper) {
    color: $black-3;
    @extend .body-2;
    align-items: center;
  }

  & :global(.ant-radio-inner) {
    width: 26px;
    height: 26px;
    border: solid 2px $grey-transparent-2;

    &:hover {
      border-color: $primary-color;
    }

    &:focus {
      border-color: $primary-color;
      background-color: $primary-color;
    }
  }

  & :global(.ant-radio) {
    top: 0;
    &:hover {
      border-color: $primary-color;
    }
  }

  & :global(.ant-radio-checked .ant-radio-inner) {
    border-color: $primary-color;

    &::after {
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      background-color: $primary-color;
    }
  }

  & :global(.ant-radio-inner) {
    &:focus {
      background-color: $purple-7;
    }
  }

  :global(.ant-radio-wrapper:hover .ant-radio, ),
  :global(.ant-radio:hover .ant-radio-inner),
  :global(.ant-radio-input:focus + .ant-radio-inner) {
    border-color: $primary-color;
    background-color: $purple-7;
  }

  & :global(.ant-radio-disabled .ant-radio-inner) {
    border-color: $grey-4 !important;

    &::after {
      background-color: $grey-4;
    }
  }
}
