#textarea {
  width: 600px;
}

@media (max-width: 1499px) {
  #textarea {
    width: 500px;
  }
}

@media (max-width: 1199px) {
  #textarea {
    width: 400px;
  }
}
