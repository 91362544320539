.group-container {
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #ecebeb;
  border-bottom: 0.5px solid #ecebeb;
}

.side-menu {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0px 0px 0px 25px;
  /* padding-left: 32px; */
  padding-top: 24px;
}

@media (max-width: 1170px) {
  .side-menu {
    max-width: 350px;
  }

  .option-item {
    min-width: 250px;
  }

  .option-item-active {
    max-width: 200px;
  }
}

.group-title {
  font-weight: 800;
  font-size: 15px;
  color: #637381;
  margin-bottom: 24px;
  margin-left: 32px;
}

.option-item {
  display: flex;
  min-width: 340px;
  margin-left: 10px;
  cursor: pointer;
  height: 58px;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  color: #637381;
  background: white;
  white-space: nowrap;
  border-left: 2px solid white;
}

.group-text {
  /* position: relative;
    display: inline-block; */
  text-overflow: ellipsis;
  /* position: relative; */
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  margin-right: 32px;
  margin-bottom: 0;
}

.group-text .tooltiptext {
  visibility: hidden;
  display: flex;
  background-color: #f4f4ff;
  color: #0e22f4;
  text-align: center;
  align-items: center;
  /* border-radius: 6px; */
  padding: 5px 0;
  height: 58px;
  padding-right: 20px;
  border-radius: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.group-text:hover .tooltiptext {
  visibility: visible;
}

.option-item::before {
  content: '';
  display: block;
  height: 20px;
  position: relative;
  top: 80px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
}

.option-item-active {
  display: flex;
  min-width: 340px;
  margin-left: 10px;
  cursor: pointer;
  height: 58px;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  background: #f4f4ff;
  color: #0e22f4 !important;
  border-left: 2px solid white;
}

.option-item:hover .icon-remove {
  background-color: #ffffff !important;
}

.icon-remove {
  background-color: rgba(246, 246, 254) !important;
  color: #0f0ff4;
  margin-top: -2px;
}

.option-item:hover {
  background: #f4f4ff;
  color: #0e22f4 !important;
}
