@import 'styles/variables/colors.module';
@import 'components/common/typography/styles.module.scss';

.root {
  &:global(.ant-checkbox-wrapper) {
    color: $blue-3;
    @extend .body-3;
    display: flex;
    align-items: flex-start;

    & :global(.ant-checkbox) {
      top: 0;
    }

    & :global(.ant-checkbox-inner) {
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      border-color: $grey-transparent-2;
      border-radius: 3px;
    }

    & :global(.ant-checkbox-inner::after) {
      top: 45%;
      left: 30%;
    }

    &:hover,
    &:focus {
      & :global(.ant-checkbox-inner) {
        border-color: $blue-3;
        border-radius: 3px;
      }
    }
  }

  & :global(.ant-checkbox-checked) {
    background-color: $blue-3;
    border-color: $blue-3;
    border-radius: 3px;

    &::after {
      border: 1px solid $blue-3;
      border-radius: 3px;
    }

    & :global(.ant-checkbox-inner) {
      background-color: $blue-3;
      border-color: $blue-3;
    }

    &:hover,
    &:focus {
      & :global(.ant-checkbox-inner) {
        border-color: $blue-3;
        border-radius: 3px;
      }
    }
  }

  & :global(.ant-checkbox-disabled) {
    & :global(.ant-checkbox-inner) {
      background-color: $light-grey;
    }
  }
}
