@import '~styles/variables/colors.module.scss';
@import '~components/common/typography/styles.module.scss';

.root {
  @extend .body-18;

  & :global(.ant-tooltip-arrow) {
    width: 40px;
    height: 14px;
  }

  & :global(.ant-tooltip-inner) {
    border-radius: 5px;
    border: 1px solid $grey-15;
    box-shadow: none;
    color: $grey-5;
    padding: 12px 20px;
  }

  & :global(.ant-tooltip-arrow-content) {
    $size: 20px;

    border: 1px solid $grey-15;
    box-shadow: none;
    width: $size;
    height: $size;
  }
}
