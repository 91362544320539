.error-body-container {
  position: fixed;
  left: 60%;
  transform: translate(-50%, -50%);
  height: 150px;
  width: 400px;
  top: 100px;
  display: flex;
  margin: auto;
  font-size: 20px;
  z-index: 3;
  transition: opacity 0.3s ease;
}

.alert {
  position: fixed;
  top: 100px;
  z-index: 2;
  border-radius: 5px;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
